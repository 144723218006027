.nav_btns {
    display: flex;
    justify-content: space-between;
    height:40px; 
    width:100%;
    background: linear-gradient(to right, #010645, #010645, #010d93, transparent);
    /* border: 1px solid white; */
    padding-left: 5vw;
    padding-right: 5vw;
    
}
.nav-btn-cont {
    width: auto;
    /* border: 1px solid white; */
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
}
.nav-btn {
    font-size:10px; 
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    /* border: 1px solid white; */
}
#heart {
    height: 93%;
    border: 1px solid transparent;
}