.filters_div {
    width: 80%;
    position: relative;
    display: flex; 
    height: 5%; 
    margin-bottom: 1%;
    /* border: 1px solid red; */
}
.dropdown_cont {
    width: 30%;
    display: flex; 
    height: 100%; 
    /* border: 1px solid green; */
    justify-content: center;
    align-items: center;
}
.dropdown_label {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
    height: auto;
    height: 30px;
    margin-top: 5px;
}
.dropdown {
    width: 60%;
    height: 25px;
    margin: 5px;
    margin-bottom: 10px;
    font-size: 20px;
    text-align: center;
    border-radius: 1px;
    border: none;
    color: black;
}

