.table-container {
    max-height: 100%; /* Adjust the height as needed */
    overflow-y: auto;
  }
  
  .container {
    padding: 1rem;
  }
  
  .title {
    font-size: 1.125rem; /* text-lg */
    font-weight: bold;
    margin-bottom: 0.5rem; /* mb-2 */
  }
  
  .table {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #D1D5DB; /* border-gray-300 */
  }
  
  .table-row {
    border: 1px solid #E5E7EB; /* border-gray-200 */
  }

  .region-row {
    border: 1px solid #E5E7EB; /* border-gray-200 */
    background: grey
  }
  
  .table-cell {
    border: 1px solid #E5E7EB; /* border-gray-200 */
    padding: 0.5rem; /* p-2 */
  }

  .fileBtn {
    padding: 10px;
    /* padding-top:15px;
    padding-bottom:15px; */
    /* margin-right: 5px; */
    background-color: grey;
    position: relative;
    left: 5px;
    cursor: pointer;
    z-index: 200;
  }