.TopBar
{
    position: relative;
    display: flex;
    /* flex-direction: column; */
    min-height: 17vh;
    max-height: 17vh;
    width: 100%;
    background: linear-gradient(to right, #010d93, transparent);
    /*max-width: 100%;*/
    /*max-height: 100% ;*/
    -webkit-background-size: cover; /* For WebKit*/
    -moz-background-size: cover;    /* Mozilla*/
    -o-background-size: cover;      /* Opera*/
    background-size: 100% 100%;
    align-items: center;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid white;
}

.headers {
    position: relative;
    display: flex;
    align-items: center;
    /* border: solid red; */
    height: 100%;

    justify-content: space-between; 
    width: 100%;
    padding: 0;
    margin: 0;
    margin-left: 0%
}

.h-item{
    height: 100%;
    /* display: block;
    position: relative; */
    /* border:solid red; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.LogoPart
{
    position: relative;
    /* width: 100%; */
    height: 80px;
}
.NavText
{
    position: relative;
    width: 100%;
    /* text-align: center; */
    margin-right:5vw;
    font-size: xx-large;
    /* border: 1px solid red; */
}
