.login-box {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    height: 320px;
    box-sizing: border-box;
    /*background: #2D2C87;*/
    /*color: #4C71E5;*/
    /*box-shadow: 5px 10px 8px 2px #07062C;*/
    /* opacity: 0.5%; */
    padding: 80px 40px;
    /*border-radius: 20px;*/
}


.login-box form label {
    display: block;
}

.login-box h4 {
    margin: 0;
    padding: 0 0 10px;
    /*    color: #efed40; */
    color: #9AA3AF;
    text-align: center;
    font-size: x-large;
}

#login_emblem {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.le_icon {
    /* background-color: transparent; */
    font-size: 500%;
    color: rgba(255, 255, 255, 0.5);
}
.le_line {
    background-color: rgba(255, 255, 255, 0.5);
    height: 3px;
    width: calc(50% - 50px);
    margin-top: 48.5px;
}
#le_circle {
    width: 100px;
    height: 100px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

#le_circle, .le_line {
    
}

.icon-cont {
    height: 30px;
    width: 12%;
    background-color: black;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
}

.textbox-cont {
    display: flex;
    color: white;
    border: 2px solid white;
    border-radius: 5px;
    height: 30px;
    margin-bottom: 15px;
    overflow: hidden;
}

.login-box label {
    font-weight: bold;
    color: #E8F0FE;
    font-size: small;
}

.login-box input {
    width: 100%;
    margin-bottom: 20px;
}

.login-box input[type="text"],
.login-box input[type="email"],
.login-box input[type="password"] {
    border: none;
    /* border-bottom: 1px solid #fff; */
    background: #fff;
    outline: none;
    height: 30px;
    color: #000000;
    font-size: 12px;
    /*border-radius: 20px;*/
    width: 88%;
    padding-left: 5px;
    padding-right: 5px;
}
::placeholder {
    color: rgba(0, 0, 0, .8);
    font-weight: bold;
}
.login-box input[type="submit"] {
    border: none;
    outline: none;
    height: 30px;
    color: #fff;
    font-size: 14px;
    /* background: #4E75E6; */
    background: linear-gradient(to right, #011378, #0026ff,#00bfff);
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}

.login-box input[type="submit"]:hover {
    /* background: #959595;
    color: #262626; */
    box-shadow: 1px 1px 10px silver;
}

.login-box a {
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
}