.ChooseCountry {
    /* background: linear-gradient(to right, #010A43, #000428); */
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
  }
  
  .flag-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    place-items: center;
    padding: 50px;
    width: 90%;
    height: 100%;;
    margin: auto;
    /* border: 1px solid white; */
  }
  
  .flag-and-name {
    cursor: pointer;
    /* border: 1px solid white; */
    width: 100px;
  }

  .flag {
    display: block;
    width: 80px;
    height: 80px;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
  }

  .country-name {
    background: linear-gradient(to right, rgba(0, 0, 139, 0.8), rgba(0, 0, 139, 0.8), rgba(100, 120, 200, 0.8));
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin-top: 5px;
  }
  